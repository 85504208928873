<template>
  <v-container>
    <p v-if="perId == 0">Acceso denegado</p>
    <div v-else>
      <p v-if="error">Fichero invalido</p>
      <p v-else-if="loading">Accediendo al fichero</p>
      <p v-else>Acceso correcto</p>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  computed:{
      ...mapState(['perId', 'urlRaiz'])
  },
  data () {
    return {
      error: false,
      loading: true,
    }
  },
  watch: {
    perId: {
      handler () {
        this.getFile();
      },
      deep: true,
    },
  },
  mounted () {
    this.getFile();
  },
  methods: {
    getFile()
    {
      if (this.perId > 0)
      {
        try
        {
            const url = atob(this.$route.params.file);
            console.log(url)
            this.viewCloudFile(url);
        }
        catch
        {
            this.error = true;
        }
      }
    },
    getCloudFileFromApi(url) {
      return new Promise((resolve) => {
        const controllerParameters = {
          Action: 'GET_CLOUD_ITEM',
          FileName: url
        };
        const AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/inf", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
          const item = result.data;

          setTimeout(() => {
              resolve({
                item
              })
          }, 1000)
        });
      })
    },
    // postInfVisToApi(fileName, ambito, folderNames) {
    //   return new Promise((resolve) => {
    //     const controllerParameters = {
    //       Action: 'SAVE_INFVIS',
    //       EsTienda: this.esTienda,
    //       InfFic: fileName,
    //       PerId: this.perId,
    //       PerIdAux: this.perIdAux,
    //       InfAmbito: ambito,
    //       FolderNames: folderNames
    //     };
    //     const AuthToken = localStorage.getItem('token');
    //     axios({ method: "POST", "url": this.urlRaiz + "/api/inf", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
    //       const success = result.data;

    //       setTimeout(() => {
    //           resolve({
    //           success
    //         })
    //       }, 1000)
    //     });
    //   })
    // },
    viewCloudFile(item)
    {
      this.getCloudFileFromApi(item)
        .then(data => {
            if(data.item == null) { 
              alert('Documento no encontrado');
              return; 
            }
            this.verDocumento(data.item.File, data.item.FileName, data.item.Mime);
        });
    },
    verDocumento(doc, docName, contentType = 'application/octet-stream'){
        this.loading = true;

        // Datos del fichero
        // const contentType = 'application/octet-stream';
        
        // Covertir de base 64 a Blob
        const byteCharacters = atob(doc);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: contentType});

        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');

        this.loading = false;
    }
  },
}
</script>
